import { SidebarNavigationContainer, SidebarNavigationItem } from "@king-ict/eupisi-ui/components";
import { useTranslation } from "react-i18next";

import { aboutProgramRoute, dashboardRoute, notificationdRoute, statementOfAccessibilityRoute } from "../../routes/routes";
import AboutProgram from "../AboutProgram/AboutProgram";
import { Dashboard } from "../Dashboard";
import { Notifications } from "../NotificationModules";
import StatementOfAccessibility from "../StatementOfAccessibility/StatementOfAccessibility";


export interface MatchParams {
  requestId: string;
  id: string;
}
export interface SidebarProps {
  sidebarIsOpen?: boolean
}

export function Sidebar({ sidebarIsOpen }: SidebarProps) {
  const { t } = useTranslation();
  

return (
<>
      <SidebarNavigationContainer
        color="primary"
        size="2"
        sidebarIsOpen={sidebarIsOpen}
        sidebarShowHamburger={false}
      >
        <SidebarNavigationItem
          name={t("sidebar.dashboard")}
          route={dashboardRoute()}
          url={"/dashboard"}
        >
          <Dashboard />
        </SidebarNavigationItem>

        <SidebarNavigationItem
          name={t("navigation.announcementList")}
          route={notificationdRoute()}
          url={"/notifications"}
        >
          <Notifications />
        </SidebarNavigationItem>

        <SidebarNavigationItem
          name={t("navigation.aboutProgram")}
          route={aboutProgramRoute}
          url={"/aboutprogram"}
          hideFromMenu={true}
        >
          <AboutProgram />
        </SidebarNavigationItem> 

        <SidebarNavigationItem
          name={t("navigation.statementOfAccessibility")}
          route={statementOfAccessibilityRoute}
          url={"/statement-of-accessibility"}
          hideFromMenu={true}
        >
          <StatementOfAccessibility/>
        </SidebarNavigationItem>

        </SidebarNavigationContainer>
    </>
  )

}