import { api } from "@king-ict/eupisi-ui/utils/apiUtils";

import { ModulesDto } from "../../models/NotificationModules";

const apiUrlPrefix: string = "modules";

export const getModules = async () => {
  return api.get(
      `${apiUrlPrefix}`
  ) as Promise<ModulesDto[]>
}