import { Switch, Route, Redirect } from "react-router-dom";

import { dashboardRoute } from "./routes";


export default function Routing() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={dashboardRoute()} />
      </Route>
    </Switch>
  );
}
