import { useEffect } from "react";

import { useKeycloak } from "@king-ict/eupisi-ui/hooks";
import { useRouteMatch } from "react-router";

import { useTypedSelector } from "../../app/rootReducer";
import { authRoute } from "../../routes/routes";

export interface MatchParams {
  requestId: string;
  id: string;
}

export interface NavbarProps {
  setImpairedStyle: () => void;
  removeImpairedStyle: () => void;
}

export const Navbar = ({
  setImpairedStyle,
  removeImpairedStyle,
}: NavbarProps) => {
  const { keycloak } = useKeycloak();
  const user = useTypedSelector((state) => state.application.user);
  const authMatch = useRouteMatch<MatchParams>(authRoute);

  useEffect(() => {
    function mutationCallback(mutationRecord: MutationRecord[]) {
      if (mutationRecord && mutationRecord.length > 0) {
        const addedNodes = mutationRecord[0].addedNodes;
        if (addedNodes) {
          document
            .getElementsByClassName("zgl-button log_in")[0]
            ?.addEventListener("click", (e) => {
              e.preventDefault();
              keycloak.login({ idpHint: "saml-nias" });
            });      
          document
            .getElementById("nonimpaired")
            ?.addEventListener("click", (e) => {
              e.preventDefault();
              setImpairedStyle();
            });
          document
            .getElementById("impaired")
            ?.addEventListener("click", (e) => {
              e.preventDefault();
              removeImpairedStyle();
            });
          document
            .getElementById("nondyslexic")
            ?.addEventListener("click", (e) => {
              e.preventDefault();
              removeImpairedStyle();
            });
          document.getElementById("larger")?.addEventListener("click", (e) => {
            e.preventDefault();
            largerFont();
          });
          document.getElementById("default")?.addEventListener("click", (e) => {
            e.preventDefault();
            defaultFont();
          });
          document.getElementById("smaller")?.addEventListener("click", (e) => {
            e.preventDefault();
            smallerFont();
          });    
        }
      }
    }

    const largerFont = () => {
      const currentFontSize = document.documentElement.style.fontSize;
      let currentFont = 100;
      if (currentFontSize != null && currentFontSize !== "") {
        const currentFontPercentage = currentFontSize.split("%")[0];
        currentFont = +currentFontPercentage;
      }

      if (currentFont < 120) {
        document.documentElement.style.fontSize = currentFont + 5 + "%";
      }
    };

    const defaultFont = () => {
      document.documentElement.style.fontSize = "100%";
    };

    const smallerFont = () => {
      const currentFontSize = document.documentElement.style.fontSize;
      let currentFont = 100;
      if (currentFontSize != null && currentFontSize !== "") {
        const currentFontPercentage = currentFontSize.split("%")[0];
        currentFont = +currentFontPercentage;
      }

      if (currentFont > 80) {
        document.documentElement.style.fontSize = currentFont - 5 + "%";
      }
    };

    const observer = new MutationObserver(mutationCallback);

    const config = {
      childList: true,
    };

    const target = document.getElementsByClassName("egradani_traka-holder");
    observer.observe(target[0], config);    

    return () => {
      observer.disconnect();
    };
  });

  useEffect(() => {
    const script = document.createElement("script");

    //TODO impementirati AUTH komponentu
    interface UserInfo {
      oib: string;
      idp: string;
      messageId: string;
      navToken: string;
    }
    const userSession = keycloak.idTokenParsed as UserInfo;

    if (userSession && keycloak.authenticated) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const forLegalIps = urlParams.get("forLegalIps");
      const forPersonOib = urlParams.get("person");

      script.src = `${process.env.REACT_APP_NIAS_NAVBAR}?messageId=${
        userSession.messageId
      }&amp;navToken=${
        userSession.navToken
      }&logout_url=${keycloak.createLogoutUrl({
        redirectUri: "http://localhost:3000/dashboard",
      })}&show_vision_impaired=true&show_font_resize=true`;

      if (!!forLegalIps) {
        const forLegalIzvorReg = urlParams.get("forLegalSource");
        const toLegalIps = urlParams.get("toLegalIps");
        const toLegalIzvorReg = urlParams.get("toLegalSource");

        script.src += `&ForLegalIps=${forLegalIps}&ForLegalIzvor_reg=${forLegalIzvorReg}&ToLegalIps=${toLegalIps}&ToLegalIzvor_reg=${toLegalIzvorReg}`;
      } else if (!!forPersonOib) {
        script.src += `&ForPersonOib=${forPersonOib}`;
      }
    } else {
      script.src = `${process.env.REACT_APP_NIAS_NAVBAR}?show_vision_impaired=true&show_font_resize=true`;
    }    

    function clearCookie(name: string, domain: string){
      const path = "/";
      const date = "Thu, 01 Jan 1970 00:00:00 GMT";
      document.cookie = name + "=; expires=" + +date + "; domain=" + domain + "; path=" + path;
  };

  script.addEventListener("load", function (e) {
    document.getElementsByClassName("zgl-button log_out")[0]
    ?.addEventListener("click", (e) => {
    clearCookie('authDataId',  document.domain === 'localhost' ? 'localhost' : '.e-upisi.hr');
    clearCookie('sessionId',  document.domain === 'localhost' ? 'localhost' : '.e-upisi.hr');

    });
    
  });

    script.src +=
      "&change_entity_url=" +
      encodeURIComponent(
          `${process.env.REACT_APP_WEB_PUBLIC}?person={ForPersonOib}&forLegalIps={ForLegalIps}&forLegalSource={ForLegalIzvor_reg}&toLegalIps={ToLegalIps}&toLegalSource={ToLegalIzvor_reg}`
      );

    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [keycloak, user, authMatch]);

  return (
    <>
      <div id="egradani" className="egradani_traka-holder"></div>
    </>
  );
};
