import { UserRole } from "./UserRole";

export interface User {
    id: number;
    oib?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    telephone?: string;
    userRoles: UserRole[];
    oibValidated: boolean;
    niasMessageId: string;
    niasNavigationToken: string;
    niasSessionId: string;
}


export const userInitial: User = {
    id: 0,
    oib: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    userRoles: [],
    oibValidated: false,
    niasMessageId: "",
    niasNavigationToken: "",
    niasSessionId: "",
  };