import React, { useMemo, useState } from "react";

import { Button, ArrowButton, Typography, Modal, InfoPanel } from "@king-ict/eupisi-ui/components";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { ValidationFailure } from "../models";
import styles from "./Toolbar.module.scss";

export interface ToolbarTopProps {
  children?: any;
  pageTitle?: string;
  showBack?: "hide" | "disable" | "show";
  showAdd?: "hide" | "disable" | "show";
  showEdit?: "hide" | "disable" | "show";
  showCustom?: boolean;
  isEdited?: boolean;
  errors?: ValidationFailure[];
  onClickAdd?: (event: React.MouseEvent<any, MouseEvent>) => void;
  onClickEdit?: (event: React.MouseEvent<any, MouseEvent>) => void;
  onClickBack?: (event: React.MouseEvent<any, MouseEvent>) => void;
}
export const ToolbarTop = ({ children, pageTitle, showBack, showAdd, showEdit, showCustom, isEdited, errors, onClickAdd, onClickEdit, onClickBack }: ToolbarTopProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const renderAddButton = useMemo(() => {
    switch (showAdd) {
      case "show":
        return (<Button className="mr-1 float-right" outline={true} size="sm" onClick={onClickAdd}>{t("common.addNew")}</Button>);
      case "disable":
        return (<Button className="mr-1 float-right" outline={true} size="sm" disabled={true}>{t("common.addNew")}</Button>);
      default:
        return (<></>)
    }
  },[t,showAdd,onClickAdd,])

  const renderEditButton = useMemo(() => {
    switch (showEdit) {
      case "show":
        return (<Button className="mr-1 float-right" outline={true} size="sm" onClick={onClickEdit}>{t("common.edit")}</Button>);
      case "disable":
        return (<Button className="mr-1 float-right" outline={true} size="sm" disabled={true}>{t("common.edit")}</Button>);
      default:
        return (<></>)
    }
  },[t, showEdit, onClickEdit])

  const renderBackButton = () => {
    switch (showBack) {
      case "show":
        return (<>
          <ArrowButton
            ariaLabel={t("accessibility.backButton")}
            backwards
            onClick={isEdited ? () => setShowCancelModal(true) : onClickBack}
            title={t("accessibility.backButton")}
          >
          </ArrowButton>
          <Typography
            variant="base"
            component="span"
            color="primary"
            className="mb-0">
            {t("common.back")}
          </Typography>
          <Modal
            type={"confirm"}
            title={t("common.cancelModalTitle")}
            cancelText={t("common.no")}
            confirmText={t("common.yes")}
            open={showCancelModal}
            onClose={() => setShowCancelModal(false)}
            onConfirm={onClickBack}
          >
            {t("common.cancelModalMessage")}
          </Modal>
        </>);
      case "disable":
        return (<></>);
      default:
        return (<></>)
    }
  }

  const renderCustomButton = () =>
    showCustom ? (<>{children}</>) : (<></>);

  const renderInfoSuccess = useMemo(() =>{
    if(location.state !== undefined && location.state==="saveOK"){
      window.scroll(0,0);
      return <InfoPanel
            color="success"
            fullWidth={true}
            message= {t("common.saveSuccess")}
            timeoutSec={3}
          />
    }
  },[t,location.state])
 
  const renderInfoError = useMemo(() =>{
    if(errors !== undefined && errors.length > 0)
    {
      console.log(errors)
      return <InfoPanel
              color="danger"        
              fullWidth={true}
              dismissible={true}
              message=""
            >
              <b>Greška kod snimanja podataka</b>
              <br></br>
              <ul>
                {errors.map(error => <li>{error.message}</li>)}
              </ul>
          </InfoPanel>
    }
  },[errors])

  return (
    <>
      <div className={styles.toolbar} >
        <Row className="pl-0">
          <Col lg="3" >
            <Col lg="12" className="pl-2">
              {renderBackButton()}
            </Col>
            <Col lg="12">
              <Typography
                variant="heading"
                className="mb-0" >
                {pageTitle}
              </Typography>
            </Col>
          </Col>
          <Col lg="6" className="pl-0">
            {renderInfoSuccess}
            {renderInfoError}
          </Col>
          <Col lg="3">
            <Col lg="12">
            {renderCustomButton()}
            {renderAddButton}
            {renderEditButton}
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
}