import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// Adding random query parameter to fetch translation on each page refresh
const backendOptions = {
  queryStringParams: { v: new Date().getUTCMilliseconds().toString() },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "hr",
    fallbackLng: "hr",
    interpolation: {
      escapeValue: false,
    },
    backend: backendOptions,
  });


export default i18n;
