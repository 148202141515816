import { PagedResult } from "@king-ict/eupisi-ui/models";
import { api } from "@king-ict/eupisi-ui/utils/apiUtils";

import { OibIdentification, OibServiceResult, SaveResult } from "../../models";
import { User, Role, UserSearch, UserPermission, UserPermissionList } from "../../models/User";

const apiUrlPrefix: string = "users";

export const getUserSession = async () => {
    return api.get(`${apiUrlPrefix}/session`) as Promise<User>;
  };

export const getUserById = async (id: number) => {
    return api.get(
        `${apiUrlPrefix}/get/${id}`
    ) as Promise<UserPermission>
}

export const searchUser = async (search: UserSearch) => {
    return api.post(
        `${apiUrlPrefix}/search`,
        search
    ) as Promise<PagedResult<User>>
}

export const saveUser = async (item: User) => {
    return api.post(
        `${apiUrlPrefix}/save`,
        item
    ) as Promise<SaveResult<User>>
}

export const getRoleList = async () => {
    return api.get(
        `${apiUrlPrefix}/get-roles`
    ) as Promise<Role[]>
}

export const getUserOibServiceData = async (oib: string) => {
    return api.get(
        `${apiUrlPrefix}/oib/${oib}`
    ) as Promise<OibServiceResult<OibIdentification>>
}

export const login = async () => {
    return api.get(
        `${apiUrlPrefix}/login`
    ) as Promise<void>
}

export const logout = async () => {
    return api.get(
        `${apiUrlPrefix}/logout`
    ) as Promise<void>
}

export const roleChange = async (id?: number) => {
    if (id)
        return api.get(
            `${apiUrlPrefix}/role-change/${id}`
        ) as Promise<UserPermissionList>
    else
        return api.get(
            `${apiUrlPrefix}/role-change`
        ) as Promise<UserPermissionList>
}
