import { combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import { codeBookApi } from "../queries/codeBookQuery";
import applicationReducer from "../slices/applicationSlice";

const rootReducer = combineReducers({
  application: applicationReducer,
  [codeBookApi.reducerPath]: codeBookApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
