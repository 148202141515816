import { useEffect, useState } from "react";

import { ExpandablePanel, ExpandablePanelBody, ExpandablePanelHeader, TextEditor, Typography } from "@king-ict/eupisi-ui/components";
import { hrDate } from "@king-ict/eupisi-ui/utils";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { getModulNotifications } from "../../api/Notification/NotificationApi";
import { ContentArea, FormCard } from "../../components";
import { ModulesAndNotificationsDto, NotificationDto } from "../../models/NotificationModules";
import styles from "./Notifications.module.scss";

export function Notifications() {
  const { t } = useTranslation();
  const [moduleAndNotifications, setModuleAndNotifications] = useState<ModulesAndNotificationsDto[]>([]);
  const [notificationsFetched, setNotificationsFetched] = useState(false);

useEffect(() => {
  getAllNotificationAndModules();
}, []);


const getAllNotificationAndModules = () => {
  (async () => {
    var result = await getModulNotifications();
    setModuleAndNotifications(result);
    setNotificationsFetched(true);
})();
};

const getModules = () => {
  if (notificationsFetched) {
    if (moduleAndNotifications.length > 0) {
      return <ContentArea>
       <Container fluid>
       <Row>
          <Col md={12}>
          <Typography
              variant="XLargeBold"
              component={"h1"}
              className={styles.title}
            >
              {t("notification.list")}
            </Typography>
          </Col>
      </Row>
        {moduleAndNotifications.map((mn) => (
           (mn.notifications.length > 0 && (
          <Row key={"on-" + mn.ordinalNumber}>
            <Col md={12} className={styles.panelsCol}>
             
                <ExpandablePanel clickableHeader className={styles.panel}>
                  <ExpandablePanelHeader clickableHeader className={styles.panelHeader}>
                    {mn.name}
                  </ExpandablePanelHeader>
                  <ExpandablePanelBody>
                    {getNotificationsFromModule(mn.notifications, mn.id)}
                  </ExpandablePanelBody>
                </ExpandablePanel>
                </Col>
                </Row>
           ))
        ))}
       </Container>
      </ContentArea>
  }
    else {
      return <ContentArea>
        <Container fluid>
          <Typography variant="baseBold">
            {t("notification.emptyList")}
          </Typography>
          </Container>
      </ContentArea>
    }
  }
  else {
    return <ContentArea>
    <Container fluid>
      </Container>
  </ContentArea>
  }

};

const getNotificationsFromModule = (notifications: NotificationDto[], moduleId: number) => {
  if (notifications.length > 0) {
    return notifications.map((n, index) => (
      <Row key={"no-" + index}>       
        <Col md={12}>
          <div className={styles.formCardInfo}>
            <FormCard cardTitle={n.title}>
              <Row>
                <Col md={12}>
                  <span className={styles.panelsSubHeader}>{hrDate(n.dateFrom)}</span>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                <TextEditor 
                  initialValue={n.text}
                  handleChange={()=>{}}
                  plaintext={true}
                 />
                  </Col>
              </Row>
            </FormCard>
          </div>
        </Col>
        </Row>
    ))
  }

    

  };
  
  return getModules();
}
