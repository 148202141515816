import { AboutPage } from "@king-ict/eupisi-ui/components";
import { useTranslation } from "react-i18next";

import { ContentArea, ToolbarTop } from "../../components";

export default function AboutProgram() {
  const { t } = useTranslation();
  return (
    <ContentArea>
      <ToolbarTop
        pageTitle={t("navigation.aboutProgram")}
      ></ToolbarTop>
      <AboutPage
        title={t("navigation.title")}
        version={t("navigation.appVersion")}
        logo={"\\eUpisiLogo.png"} />
    </ContentArea>
  );
}