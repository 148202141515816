export const authRoute = `/auth`;

export const dashboardRoute = (): string => "/dashboard";
export const notificationdRoute = (): string => "/notifications";

//export const importantDateListRoute = `/important-date`;
export const editImportantDateRoute = `/school-years/:id`;
export const schoolYearListRoute = "/school-years";
export const editImportantDateUrl = (id: number) =>
  `/school-years/${id}`;
export const schoolCandidateRoute = "/school-candidates";
export const schoolCandidateDetailRoute = `/school-candidates/:id`;
export const schoolCandidateDetailUrl = (id: number) =>
  `/school-candidates/${id}`;

export const schoolCandidateEditRoute = `/school-candidates/edit/:id`;
export const schoolCandidateEditUrl = (id: number) =>
  `/school-candidates/edit/${id}`;

export const schoolCandidateInstitutionSummaryListRoute = "/school-candidates-institutions";
export const schoolCandidateInstitutionListRoute = `/school-candidates-institutions/:id`;
export const schoolCandidateInstitutionListUrl = (id: number) =>
  `/school-candidates-institutions/${id}`;
export const schoolCandidateInstitutionReassignmentRoute = "/school-candidates-reassignment/:id";
export const schoolCandidateInstitutionReassignmentUrl = (id: number) =>
  `/school-candidates-reassignment/${id}`;

export const aboutProgramRoute = "/aboutprogram";
export const helpAndSupportRoute = "/help-and-support";
export const statementOfAccessibilityRoute = "/statement-of-accessibility";

export const faqGroupsAndItemsRoute = "/faq";
export const faqGroupListRoute = "/faq-group";
export const faqGroupDetailRoute = "/faq-group/:id";
export const faqGroupDetailUrl = (id: number) => `/faq-group/${id}`;
export const faqGroupEditRoute = "/faq-group/edit/:id";
export const faqGroupEditUrl = (id: number) => `/faq-group/edit/${id}`;
export const faqItemListRoute = "/faq-item";
export const faqItemDetailRoute = "/faq-item/:id";
export const faqItemDetailUrl = (id: number) => `/faq-item/${id}`;
export const faqItemEditRoute = "/faq-item/edit/:id";
export const faqItemEditUrl = (id: number) => `/faq-item/edit/${id}`;

export const announcementListRoute = "/announcement";
export const announcementDetailRoute = "/announcement/:id";
export const announcementDetailUrl = (id: number) => `/announcement/${id}`;
export const announcementEditRoute = "/announcement/edit/:id";
export const announcementEditUrl = (id: number) => `/announcement/edit/${id}`;

export const committeeListRoute = "/committee";
export const committeeDetailRoute = "/committee/:id";
export const committeeDetailUrl = (id: number) => `/committee/${id}`;
export const committeeEditRoute = "/committee/edit/:id/:institutionId?";
export const committeeEditUrl = (id: number, institutionId?: number) => 
  institutionId ? `/committee/edit/${id}/${institutionId}` : `/committee/edit/${id}`;
export const committeeApprovalListRoute = "/committee-approval";
export const committeeApprovalDetailRoute = "/committee-approval/:id";
export const committeeApprovalDetailUrl = (id: number) => `/committee-approval/${id}`;
export const committeeApprovalEditRoute = "/committee-approval/edit/:id";
export const committeeApprovalEditUrl = (id: number) => `/committee-approval/edit/${id}`;
export const countyCommitteeListRoute = "/committee-county";
export const countyCommitteeDetailRoute = "/committee-county/:id";
export const countyCommitteeDetailUrl = (id: number) => `/committee-county/${id}`;
export const countyCommitteeEditRoute = "/committee-county/edit/:id/:countyId?";
export const countyCommitteeEditUrl = (id: number, countyId?: number) => 
  countyId ? `/committee-county/edit/${id}/${countyId}` : `/committee-county/edit/${id}`;
export const ministryCommitteeListRoute = "/committee-ministry";
export const ministryCommitteeDetailRoute = "/committee-ministry/:id";
export const ministryCommitteeDetailUrl = (id: number) => `/committee-ministry/${id}`;
export const ministryCommitteeEditRoute = "/committee-ministry/edit/:id";
export const ministryCommitteeEditUrl = (id: number) => `/committee-ministry/edit/${id}`;

export const userRoleRoute = "/users-roles";
export const roleListRoute = "/users-roles/roles";
export const userListRoute = "/users-roles/users";
export const userDetailRoute = "/users-roles/users/:id";
export const userDetailUrl = (id: number) => `/users-roles/users/${id}`;
export const userEditRoute = "/users-roles/users/edit/:id";
export const userEditUrl = (id: number) => `/users-roles/users/edit/${id}`;

export const institutionListRoute = "/institution";
export const institutionDetailsRoute = `/institution/:id/details`;
export const institutionDetailsUrl = (id: number) =>
  `/institution/${id}/details`;
export const institutionEditRoute = `/institution/:id/edit`;
export const institutionEditUrl = (id: number) =>
  `/institution/${id}/edit`;
export const houseNumberInstitutionListRoute = "/house-numbers";

export const requestListRoute = "/request";
export const requestCreateRoute = "/request/create";
export const requestEditRoute = "/request/edit/:id";
export const requestEditUrl = (id: number) => `/request/edit/${id}`;
