import { api } from "@king-ict/eupisi-ui/utils/apiUtils";

import { ModulesAndNotificationsDto } from "../../models/NotificationModules";

const apiUrlPrefix: string = "notifications";

export const getModulNotifications = async () => {
  return api.get(
      `${apiUrlPrefix}`
  ) as Promise<ModulesAndNotificationsDto[]>
}