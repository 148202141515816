


export interface ContentAreaProps {
  children?: any;
}
export const ContentArea = ({ children}: ContentAreaProps) => {
  return (
    <>
      <div className="mt-4 pr-5 mr-5">
          {children}
      </div>
    </>
  );
}