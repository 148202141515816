import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getUserSession } from "../api/User/UserApi";
import { User, userInitial } from "../models/User";

interface ApplicationState {
  user: User;
}

const initialState: ApplicationState = {
  user: userInitial,
};

export const getUser = createAsyncThunk("application/getUser", async () => {
  const user = await getUserSession();
  return user;
});

const applicationSlice = createSlice({
  name: "application",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
  },
});

export default applicationSlice.reducer;
