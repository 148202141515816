//import { CodeBook } from "@king-ict/eupisi-ui/models";
import { CodeBook } from "@king-ict/eupisi-ui/models";
import { axiosBaseQuery } from "@king-ict/eupisi-ui/utils";
import { createApi } from "@reduxjs/toolkit/query/react";

import { SchoolYearCodeBook } from "../models";

export const codeBookApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getSchoolYears: build.query<SchoolYearCodeBook[], void>({
      query: () => "school-years",
    }),
    getElectiveSubjects: build.query<CodeBook[], void>({
      query: () => "subjects/elective",
    }),
    getLanguages: build.query<CodeBook[], void>({
      query: () => "languages",
    }),
    getInstitutionShifts: build.query<CodeBook[], void>({
      query: () => "institutions/shifts",
    }),
    getPrograms: build.query<CodeBook[], void>({
      query: () => "programs/all",
    }),
  }),
});

export const {
  useGetSchoolYearsQuery,
  useGetElectiveSubjectsQuery,
  useGetLanguagesQuery,
  useGetInstitutionShiftsQuery,
  useGetProgramsQuery
} = codeBookApi;
