import { Button, Typography } from "@king-ict/eupisi-ui/components";
import { useKeycloak } from "@king-ict/eupisi-ui/hooks";
import { Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";

import { ReactComponent as MenuIcon } from "../../assets/images/MenuIcon.svg";
import login_logo from "./images/prijava.svg";
import styles from "./Navigation.module.scss";

export interface NavigationProps {
  onIconClick?: (event: React.MouseEvent<any, MouseEvent>) => void;
}

export function Navigation({ onIconClick }: NavigationProps) {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  return (
    <>
      <Navbar className={styles.navbar} expand="sm" >
        <Container className="p-0 m-0" fluid>
          <Navbar.Brand
            className="pb-3"
          >
            <Button
              boxless={true}
              onClick={onIconClick}
              className="pb-2"
            >
              <MenuIcon />
            </Button>
            <Typography
              component="span"
              variant="largeBold"
              color="light"
              className="pl-4"
            >
              {t("navigation.title")}
            </Typography>
          </Navbar.Brand>
          {/* <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <UserLoginBox />
          </Navbar.Collapse> */}
          {/* {!keycloak.authenticated ? (
            <div className={styles.loginContainer}>
              <a
                href={`${process.env.REACT_APP_WEB_PUBLIC}`}
                className={styles.loginButton}
                onClick={(e) => {
                  e.preventDefault();
                  keycloak?.login({ idpHint: "saml-nias" });
                }}
              >
                <img src={login_logo} alt="Prijava"></img>
                <div>{t("login.loginButton")}</div>
              </a>
            </div>
          ) 
          :  
          (
            <div className={styles.loginContainer}>
              <a
                href={`${process.env.REACT_APP_WEB_PUBLIC}`}
                className={styles.loginButton}
                onClick={(e) => {
                  e.preventDefault();
                  keycloak?.logout();
                }}
              >
                <img src={login_logo} alt="Odjava"></img>
                <div>{t("login.logoutButton")}</div>
              </a>
            </div>
          )
          }       */}
        </Container>
      </Navbar>
    </>
  );
}

