import React, { useCallback, useEffect, useState } from "react";

import { Footer, FooterItem } from "@king-ict/eupisi-ui/components";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router";

import { getUserManual as  getUserManualApi} from "../../api/Documents/DocumentApi";
import { aboutProgramRoute, statementOfAccessibilityRoute } from "../../routes/routes";
import { Navbar } from "../Navbar/Navbar";
import { Sidebar } from "../Sidebar/Sidebar";
import styles from "./Layout.module.scss";
import { Navigation } from "./Navigation";

interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  
  const history = useHistory();

  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  useEffect(() => {
    let fn = () => {
        if (window.innerWidth <= 768) {
            setShowSidebar(false);
          } else {
            setShowSidebar(true);
          }
    };

    fn();

    window.addEventListener("resize", fn);

    return () => window.removeEventListener("resize", fn);
}, []);

const setImpairedStyle = useCallback(() => {
  document.documentElement.classList.add("theme-vision-impaired");
  document.documentElement.classList.remove("theme-default");
}, []);

const removeImpairedStyle = useCallback(() => {
  document.documentElement.classList.add("theme-default");
  document.documentElement.classList.remove("theme-vision-impaired");
}, []);

const showAboutProgram = () => history.push(aboutProgramRoute);
const showIzjavaOPristupacnosti = () => {
  window.scrollTo(0, 0);
  history.push(statementOfAccessibilityRoute);
}

const footerItems: Array<FooterItem> = [
  {
    label: "Korisničke upute",
    callback: (item: FooterItem) => {
      getUserManualApi();
      return false;
    },
  },
  {
    label: "O programu",
    callback: (item: FooterItem) => {
      showAboutProgram();
      return false;
    },
  },
  {
    label: "Izjava o pristupačnosti",
    callback: (item: FooterItem) => {
      showIzjavaOPristupacnosti();
      return false;
    },
  },
];
  
  return (
    <>
      <Navbar
        setImpairedStyle={setImpairedStyle}
        removeImpairedStyle={removeImpairedStyle}
      />
      <Navigation onIconClick={() => setShowSidebar(!showSidebar)} />
      <main className={styles.main}>
        <Container fluid>
          <Sidebar sidebarIsOpen={showSidebar} />
          {children}   
        </Container>     
      </main>     
      <Footer
        items={footerItems}
      />
    </>
  );
}
